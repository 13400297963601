import axios from 'axios';
import type { HomeSearchResult } from '~/types';

export function useKnowledgeService() {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/datasets/sources`,
        // baseURL: `http://localhost:3000`,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=3600',
            ...useRequestHeaders(['cookie']),
        }
    })

    async function listKnowledgeSources() {
        const { data } = await api.get('');

        return data;
    }

    async function createWebPage(url: string, target: 'user' | 'organization') {
        const { data } = await api.post('/webpage', { url, target });

        return data;
    }

    async function getKnowledgeSource(id: string) {
        const { data } = await api.get(`/${id}`);

        return data;
    }

    async function searchKnowledgeSources(query: string) {
        const { data } = await api.get<HomeSearchResult[]>(`/search?q=${query}`);
        return data;
    }

    async function createFolder(folder: any) {
        const { data } = await api.post('/folders', folder);
        return data;
    }

    async function deleteFolder(folderId: string) {
        await api.delete(`/folders/${folderId}`);
    }

    async function updateFolder(folderId: string, folder: any) {
        const { data } = await api.patch(`/folders/${folderId}`, folder);
        return data;
    }

    async function moveFolder(folderId: string, newParentFolderId?: string | null) {
        const { data } = await api.patch(`/folders/${folderId}`, { SourceFolderId: newParentFolderId });
        return data;
    }

    async function moveSource(sourceId: string, targetFolderId: string) {
        const { data } = await api.patch(`/${sourceId}`, { SourceFolderId: targetFolderId });
        return data;
    }

    async function deleteSource(sourceId: string) {
        await api.delete(`/${sourceId}`);
    }

    async function updateSource(sourceId: string, source: any) {
        const { data } = await api.patch(`/${sourceId}`, source);
        return data;
    }

    return {
        updateSource,
        deleteSource,
        moveSource,
        listKnowledgeSources,
        createWebPage,
        getKnowledgeSource,
        searchKnowledgeSources,
        createFolder,
        deleteFolder,
        updateFolder,
        moveFolder
    }
}